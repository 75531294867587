
import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  computed: {
    ...mapGetters(["companiesCount", "companiesList"]),
  },
  methods: {
    getCompanyData: function (id: number) {
      this.$store.dispatch("getCompanyData", id).then((response: any) => {
        this.$router.push("/informacje-organizacyjne");
      });
    },
    logout: function () {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/");
      });
    },
  },
});
